@import "~react-image-gallery/styles/css/image-gallery.css";

.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* outline: 1px solid wheat; */
  height: 60dvh;
}

.image-gallery-image {
  /* outline: 2px solid green; */
  height: 70vh;
  height: 70dvh;
}

.image-gallery {
  width: 100%;
  height: 100;
}

.gallery-img {
  width: 30%;
  margin-bottom: 30px;
  border: 1px solid white;
}

@media (max-width: 500px) {
  .gallery-img {
  width: 90%;
}
}

nav.image-gallery-thumbnails-container {
  height: 10vh;
  height: 10dvh;
}