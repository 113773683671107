.burger-menu {
  display: none;
}

.burger-links {
    display: none;
}

.none {
  display: none;
}

@media (max-width: 788px) {
  .burger-menu {
    display: block;
    position: absolute;
    right: 1rem;
    top: 0;
    width: 10%;
    z-index: 999;
  }

  button {
    background: transparent;
    border: none;
    color: white;
    cursor: pointer;
    margin-top: 10px;
    width: 1.5rem;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
  }
  @media (hover: hover) {
    button {
      border-radius: 0.4rem;
      transition: background-color 100ms;
    }
    button:hover {
      background: rgba(255, 255, 255, 0.06);
    }
    button:active {
      background: rgba(255, 255, 255, 0.1);
    }
  }
  svg {
    width: 100%;
    height: 100%;
    overflow: visible;
    transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  path {
    transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  path:nth-child(1) {
    transform-origin: 5% 10%;
  }
  path:nth-child(2) {
    transition-property: opacity;
  }
  path:nth-child(3) {
    transform-origin: 5% 90%;
  }
  button.open_btn svg {
    transform: rotate(-180deg);
  }
  button.open_btn path:nth-child(1) {
    transform: rotate(45deg) scaleX(1.35);
  }
  button.open_btn path:nth-child(2) {
    opacity: 0;
  }
  button.open_btn path:nth-child(3) {
    transform: rotate(-45deg) scaleX(1.35);
  }

  .none {
    transition: .5s;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
  }
  
  .burger-links {
    transition: 0.5s;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    /* bottom: 50dvh; */
    padding-top: 40px;
    background: transparent;
    backdrop-filter: blur(10px);
    opacity: 0.9;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    z-index: 2;
  }
}

