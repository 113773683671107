.booking {
  height: 70vh;
  height: 70dvh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 50px;
}

h1 {
  font-size: 1.5em;
  font-weight: 300;
}

.contacts {
  padding: 50px;
}

.king {
  text-decoration: underline;
}

.hotel {
    font-size: 0.7em;
    position: relative;
    bottom: -2rem;
    left: 0;
    right: 0;
    grid-column-end: span 2;
    padding: 0;
}

.yandex {
  border: 1px solid;
  border-radius: 0;
}

.links {
  margin-top: 2rem;
  padding: 5px;
  border: 1px solid;
  border-radius: 0;
  margin-left: 10px;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

@media (max-width: 37.5rem) {
  .booking {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr 1fr;
    /* margin-top: 0; */
  }

  .contacts-titles {
    font-size: 1em;
  }

  .contacts {
    font-size: smaller;
    padding: 0;
    padding-bottom: 5px;
  }

  .hotel {
    grid-column-end: auto;
  }
}