@media (max-width: 788px) {
  .menu {
    width: 95%;
    margin-top: 70px;
  }

  .main {
    flex-direction: column;
  }

  .titles {
    flex-direction: row;
    width: 100%;
    height: auto;
    overflow: scroll;
    margin-bottom: 20px;
  } 
  .menu-subtitle {
    border: 1px solid #fff;
    border-radius: 5px;
    font-weight: normal;
    font-size: 1rem;
    padding: 2px 5px;
    margin-right: 10px;
    width: auto;
    white-space: nowrap;
  }

  .items {
    width: 100%;
    flex: 1;
  }

  .name {
    font-size: 1rem;
  }

  .item_name {
    flex-basis: 50%;
  }

  .columns {
    flex-basis: 45%;
  }
}
